import React from "react"

import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import Layout from "../components/layout"
import SEO from "../components/seo"

function Description(props) {
  return (
    <Typography variant="subtitle1" gutterBottom>
      {props.site.description}
    </Typography>
  )
}

class AboutPage extends React.Component<{ site: any }> {
  render() {
    return (
      <Layout>
        <SEO title="About" />
        <Typography component="h4" variant="h4" gutterBottom>
          About
        </Typography>
        <Description />
      </Layout>
    )
  }
}

export default AboutPage
